import { useCallback, useContext, useEffect, useState } from 'react';

import { APIBaseUrlContext } from '@core/context';
import { fetchWrapper } from '@core/hooks/deprecated/useReadmeApi';
import usePrevious from '@core/hooks/usePrevious';
import { useReadmeApiRealtime } from '@core/hooks/useReadmeApi';
import useRetry from '@core/hooks/useRetry';
import useTimezone from '@core/hooks/useTimezone';

function usePollMetrics(path, ephemeralHAR, acceptResponse) {
  const prevEphemeralHAR = usePrevious(ephemeralHAR);
  const apiBaseUrl = useContext(APIBaseUrlContext);
  const [response, setResponse] = useState(null);

  // If timezone doesn't resolve for some reason, we don't want requests to fail
  // We'll add a custom fallback to UTC in this scenario
  const timezone = useTimezone() || 'UTC';

  const pollMetrics = useCallback(() => {
    return fetchWrapper(apiBaseUrl, {
      headers: {
        'x-timezone': timezone,
      },
      path,
    });
  }, [apiBaseUrl, path, timezone]);

  const handlePollMetrics = nextResponse => {
    const isAccepted = acceptResponse(response, nextResponse);
    if (isAccepted) setResponse(nextResponse);
    return isAccepted;
  };

  // Re-fetch data when we get a response from our parent component
  const isReady = prevEphemeralHAR != null && ephemeralHAR && ephemeralHAR !== prevEphemeralHAR;
  useRetry({ isReady, action: pollMetrics, callback: handlePollMetrics });
  const { data, isLoading } = useReadmeApiRealtime(path);

  useEffect(() => {
    if (data) setResponse(data);
  }, [data]);

  return { data: response, isLoading };
}

export default usePollMetrics;
