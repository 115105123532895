import type { PageClientSide } from '@readme/backend/models/page/types';
import type { ProjectClientSide } from '@readme/backend/models/project/types';

import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import useReadmeApi from '@core/hooks/deprecated/useReadmeApi';
import usePagination from '@core/hooks/usePagination';
import { safelyStringifyJSON } from '@core/utils/json';

import DateLine from '@ui/DateLine';
import PageThumbs from '@ui/PageThumbs';
import PaginationControls from '@ui/PaginationControls';
import WhatsNext from '@ui/WhatsNext';

interface DocFooterProps {
  apiBaseUrl: string;
  doc: PageClientSide;
  project: ProjectClientSide;
  user?: {
    email?: string;
  };
}

function DocFooter({ doc, project, user, apiBaseUrl }: DocFooterProps) {
  const { initRequest } = useReadmeApi(apiBaseUrl);
  const { prev, next } = usePagination<PageClientSide>(doc);
  const location = useLocation();
  const pathname = location?.pathname;

  const pageType = doc?.isReference ? 'reference' : 'docs';

  const sendToMetrics = useCallback(
    body => {
      return initRequest({
        method: 'POST',
        path: 'thumbvote',
        body: safelyStringifyJSON(body) || '',
      });
    },
    [initRequest],
  );

  const showWhatsNext = Boolean(doc?.next?.pages?.length || doc?.next?.description);

  return (
    <React.Fragment key={pathname}>
      <div className="UpdatedAt">
        <DateLine icon="icon icon-watch" prefix="Updated" tag="p" time={doc?.updatedAt} />
      </div>
      <hr className="NextStepsDivider" />
      {showWhatsNext ? (
        <WhatsNext
          description={doc.next.description}
          label={project.appearance.nextStepsLabel}
          pages={doc.next.pages}
        />
      ) : (
        <PaginationControls next={next} pageType={pageType} prev={prev} />
      )}
      {!!project?.metrics?.thumbsEnabled && (
        <PageThumbs
          email={user?.email}
          pathname={pathname}
          projectSubdomain={project.subdomain}
          sendToMetrics={sendToMetrics}
        />
      )}
    </React.Fragment>
  );
}

export default DocFooter;
