import Oas from 'oas';
import { Operation } from 'oas/operation';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { APIBaseUrlContext, ProjectContext, UserContext } from '@core/context';

import Header from '@ui/API/Header';
import Response from '@ui/API/Response';
import SectionHeader from '@ui/API/SectionHeader';
import Footer from '@ui/DocFooter';
import Flex from '@ui/Flex';
import RDMD from '@ui/RDMD';

import AuthContainer from '../components/AuthContainer';
import RequestContainer from '../components/RequestContainer';
import { EphemeralHARContext } from '../context/HARContext';

import YourAPIKeys from './components/YourAPIKeys';
import classes from './style.module.scss';

const Authentication = ({ doc, inputRef, oas, oasPublicUrl, oauth, onError, operation, requestsEnabled, sidebar }) => {
  const apiBaseUrl = useContext(APIBaseUrlContext);
  const { project } = useContext(ProjectContext);
  const { user } = useContext(UserContext) || {};
  const { clearEphemeralHAR, ephemeralHAR, setEphemeralHAR } = useContext(EphemeralHARContext);
  const [selectedHar, setSelectedHar] = useState(null);

  // todo: the language should be pulled from dash configuration
  const language = 'shell';

  return (
    <>
      <article className="rm-Article" id="content">
        <Header className={classes['RealtimePage-Header']} doc={doc} sidebar={sidebar} />
        <YourAPIKeys oas={oas} />
        {!!doc?.body && <RDMD key={doc.slug} body={doc.body} />}
        <Footer apiBaseUrl={apiBaseUrl} doc={doc} project={project} user={user} />
      </article>
      <div className={`${classes.rightColumn}`}>
        <SectionHeader heading="How to Authenticate" />
        <Flex align="stretch" gap="md" layout="col">
          <AuthContainer
            apiDefinition={oas}
            customLoginEnabled={!!project.oauth_url}
            hasLegacyUI={false}
            inputRef={inputRef}
            oauth={oauth}
            operation={operation}
            setSelectedHar={setSelectedHar}
          />
          <RequestContainer
            apiDefinition={oas}
            har={selectedHar}
            language={language}
            onError={onError}
            operation={operation}
            project={project}
            requestsEnabled={requestsEnabled}
            setResponseHAR={setEphemeralHAR}
            url={oasPublicUrl}
          />
          <Response
            apiDefinition={oas}
            har={selectedHar || ephemeralHAR || null}
            onExampleRemove={clearEphemeralHAR}
            operation={operation}
            requestsEnabled={requestsEnabled}
            setSelectedHar={setSelectedHar}
          />
        </Flex>
      </div>
    </>
  );
};

Authentication.propTypes = {
  /**
   * The page document to render.
   */
  doc: PropTypes.object,
  /**
   * A [React ref](https://reactjs.org/docs/refs-and-the-dom.html), which can be created as an empty ref and passed in.
   * This is used for focusing on the `<input>`.
   */
  inputRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or anything because Jest, the client, and the server
    // cannot all be pleased at once!
    // https://stackoverflow.com/a/51127130
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  /**
   * An `Oas` class instance.
   * @link https://npm.im/oas
   */
  oas: PropTypes.instanceOf(Oas).isRequired,
  oasPublicUrl: PropTypes.string,
  /**
   * Indicates whether project contains an OAuth URL.
   */
  oauth: PropTypes.bool,
  /** Triggered when errors occur in the "Try It!" button flow. */
  onError: PropTypes.func,
  /**
   * An `Operation` class instance. This can be accessed from `apiDefinition` by running `.operation('/pets', 'get')`.
   */
  operation: PropTypes.instanceOf(Operation).isRequired,
  /**
   * Does the API definition allow making requests?
   * @link https://docs.readme.com/main/docs/openapi-extensions#disable-the-api-explorer
   */
  requestsEnabled: PropTypes.bool,
  sidebar: PropTypes.array,
};

export default Authentication;
